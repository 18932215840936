import {WOW} from "wowjs";
import SwipeListener from "swipe-listener";
import { polyfill } from "seamless-scroll-polyfill";

polyfill();

let player;
window.onYouTubeIframeAPIReady = () => {
	player = new window.YT.Player('player', {
		videoId: "sBrsQiSEtFI",
		playerVars: {
			autoplay: 0,
			rel: 0,
			showinfo: 0,
			controls: 0,
			modestbranding: 1
		}
	});
}

document.addEventListener('DOMContentLoaded', () => {
	new WOW({offset: 100}).init();

	const toggleNavbar = document.querySelector('#toggle-navbar');
	const hideNavbar = () => {
		toggleNavbar.checked = false;
		toggleNavbar.dispatchEvent(new Event('change'));
	};
	toggleNavbar.addEventListener('change', () => {
		if(toggleNavbar.checked) {
			document.documentElement.classList.add('no-scroll');
		} else {
			document.querySelector('.navbar').scrollTop = 0;
			document.documentElement.classList.remove('no-scroll');
		}
	});

	Array.from(document.querySelectorAll('.button-video')).forEach(play => {
		play.addEventListener('click', (event) => {
			event.preventDefault();
			document.body.classList.add('overlay-open');
			player.playVideo();
		})
	});
	document.querySelector('.overlay-close').addEventListener('click', (event) => {
		event.preventDefault();
		document.body.classList.remove('overlay-open');
		player.stopVideo();
	});

	Array.from(document.querySelectorAll('a[href^="#"]')).forEach((element) => {
		const sectionElement = document.getElementById(element.getAttribute('href').slice(1));
		element.addEventListener('click', event => {
			event.preventDefault();
			hideNavbar();
			sectionElement.scrollIntoView({
				behavior: 'smooth',
				block: 'center', inline: 'center'
			});
		});
	});

	let n = 0;
	const avatarsContainer = document.querySelector(".avatars-container");
	const avatars = Array.from(document.querySelectorAll(".avatar-badge"));
	const overlay = document.querySelector(".avatar-overlay");

	const setNthAvatar = (index) => {
		if(matchMedia("screen and (max-width: 1169px))")) {
			n = Math.min(avatars.length - 1, Math.max(0, index));
			const nodes = Array.from(overlay.children);

			nodes.forEach((node, i) => node.className = i < n - 1 ? "opaque" : "");
			nodes[n].className = "center";

			avatars.forEach(avatar => avatar.classList.remove("open"));
			avatars[n].classList.add("open");
			document.body.classList.add("open-avatar");
		}
	}

	const onSwipe = (e) => {
		if(matchMedia("screen and (max-width: 1169px))") && document.body.classList.contains("open-avatar")) {
			const {left, right} = e.detail.directions;
			if(left) {
				setNthAvatar(n + 1);
			} else if(right) {
				setNthAvatar(n - 1);
			}
		}
	}

	avatars.forEach((avatar, index) => {
		const imageElement = avatar.querySelector(".avatar-image").cloneNode();
		imageElement.className = "";
		imageElement.addEventListener("click", () => setNthAvatar(index));
		overlay.appendChild(imageElement);

		avatar.addEventListener("click", () => setNthAvatar(index));

		avatar.addEventListener("mouseover", () => avatarsContainer.classList.add("hovered"));
		avatar.addEventListener("mouseout", () => avatarsContainer.classList.remove("hovered"));
	});

	document.querySelector(".avatar-close").addEventListener("click", () => {
		avatars.forEach(avatar => avatar.classList.remove("open"));
		document.body.classList.remove("open-avatar");
	});

	SwipeListener(document.body, {minHorizontal: 100, mouse: true});
	document.body.addEventListener("swipe", onSwipe);

	const badgeText = document.querySelector(".badges-active");
	const badges = Array.from(document.querySelectorAll(".badge"));

	badges.forEach(badge => addEventListener("click", ({ target }) => {
		const prevBadgeState = target.classList.contains("is-active");
		badges.forEach(b => b.classList.remove("is-active"));

		if(!prevBadgeState) {
			target.classList.add("is-active");
			badgeText.textContent = target?.children[0]?.textContent;
			badgeText.classList.add("is-active");
		} else {
			badgeText.textContent = "";
			badgeText.classList.remove("is-active");
		}
	}));
});
